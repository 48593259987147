import React, { Component } from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';

function App() {
    const [posts, setPosts] = useState([]);
  
    useEffect(() => {
      axios
        .get("https://menu.roydtechs.com/api/v1/meals")
        .then((result) => {
          console.log(result.data);
          setPosts(result.data);
        })
        .catch((error) => console.log(error));
    }, []);
    
   
        return (
            <div className="App">  
            {posts.map((data) => {
                    return (
                       
            <div className="movie_card"  >
                            <div className="movie_header" >
                                <img className="locandina" src={data.photo} />
                                <h4>{data.name}</h4>
                                <p className="type">{data.description}</p>
                                <span className="minutes">{data.price }</span>
                                
                            </div>
                            
                                 
                    </div> 
                    );
                          })}
             </div>  
        );
    


    }

export default App;