import React, { Component } from 'react';
import  { Fragment } from "react";
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from "react";
import axios from "axios";

function App() {
 const [posts, setPosts] = useState([]);
 const [photos, setPhotos] = useState([]);
 const [categories, setCategories] = useState([]);
 useEffect(() => {
    axios
      .get("https://menu.roydtechs.com/api/v1/meals")
      .then((result) => {
        console.log(result.data);
        setPosts(result.data);
      })
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    axios
      .get("https://menu.roydtechs.com/api/v1/categories")
      .then((result) => {
        console.log(result.data);
        setCategories(result.data);
      })
      .catch((error) => console.log(error));
  }, []); 



        return (
            <div>
<main role="main">

<section class="jumbotron text-center">
  <div class="container">
    <h1>Fidel Hotel Menu</h1>
   {/* {categories.map((cat) => {
    return(
<a href="#" className=" btn btn-outline ">{cat.name} &nbsp;&nbsp;</a>
    );
  })} */}
      
      {/* <a href="#" class="btn btn-secondary my-2">Drinks</a> */}
    
  </div>
</section>

<div className="album py-5 bg-light">
  <div className="container">

    <div className="row">
    {posts.map((data) => {
        return (
      <div className="col-md-4">
        <div className="card mb-4 shadow-sm">
          {/* <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/sv" role="img" aria-label="Placeholder: Loading image..." preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"/><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg> */}
        {data.media.map((step, stepIndex) => (
                  <Fragment key={stepIndex}>
                   {/* <p>{}</p>  */}
            <img src={step.thumbnail} class="card-img-top" alt="image loading" />

                  </Fragment>
                   ))}  
                  
          <div className="card-body">
          <h5 className="card-title"><b style={{color:"SteelBlue"}}> {data.name}</b></h5>
            <p className="card-text"><i style={{fontSize:"1rem"}}> {data.description}</i></p>
            <div className="d-flex justify-content-between align-items-center">
              <p>{data.price} Birr</p>
           
           
            </div>
          </div>
        </div>
      </div>
       );
    })}
      
    
    </div>
  </div>
</div>

</main>
            </div>
        );
  
    }
export default App;